code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

.pre-class {
  background: #f1fbfe;
  padding: 10px;
  border: 1px solid #2773bb;
  font-size: small;
}

.hidden {
  display: none;
}
.center {
  text-align: center;
}

.bold {
  font-weight: bold;
}

.lighter {
  font-weight: lighter;
}

.flex-h {
  display: flex;
}
.flex-v {
  display: flex;
  flex-direction: column;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-1 {
  flex: 1 !important;
}
.flex-2 {
  flex: 2 !important;
}
.flex-3 {
  flex: 3 !important;
}
.flex-4 {
  flex: 4 !important;
}
.flex-5 {
  flex: 5 !important;
}

.text-center {
  text-align: center;
}

.margin-auto {
  margin: auto;
}

.text-right {
  text-align: right;
}

.small {
  font-size: small;
}

.brdr {
  border: 1px solid lightgray;
}

.brdr-b {
  border-bottom: 1px solid lightgray;
}

.brdr-r {
  border-right: 1px solid lightgray;
}

.brdr-t {
  border-top: 1px solid lightgray;
}

.brdr-l {
  border-left: 1px solid lightgray;
}

$units: 1, 2, 3, 4, 5, 6, 7, 8, 9;
@each $unit in $units {
  .color-#{$unit} {
    color: '##{$unit}#{$unit}#{$unit}#{$unit}#{$unit}#{$unit}';
  }  
  .opacity-#{$unit} {
    opacity: calc(#{$unit}/10);
  }  
}

.red-border{
  border-color: red !important;
}

$sizes: 0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 105, 110, 115, 120, 125, 130, 135, 140, 145, 150, 155, 160, 165, 170, 175, 180, 185, 190, 195, 200;

@each $size in $sizes {
  .h-#{$size} {
    height: #{$size}px !important;
  }
  .lh-#{$size} {
    line-height: #{$size}px !important;
  }  
  .pad-#{$size} {
    padding: #{$size}px !important;
  }
  .mar-#{$size} {
    margin: #{$size}px !important;
  }
  .pad-r-#{$size} {
    padding-right: #{$size}px !important;
  }
  .pad-l-#{$size} {
    padding-left: #{$size}px !important;
  }
  .pad-t-#{$size} {
    padding-top: #{$size}px !important;
  }
  .pad-b-#{$size} {
    padding-bottom: #{$size}px !important;
  }
  .pad-tb-#{$size} {
    padding-bottom: #{$size}px !important;
    padding-top: #{$size}px !important;
  }
  .pad-lr-#{$size} {
    padding-left: #{$size}px !important;
    padding-right: #{$size}px !important;
  }
  .mar-r-#{$size} {
    margin-right: #{$size}px !important;
  }
  .mar-l-#{$size} {
    margin-left: #{$size}px !important;
  }
  .mar-t-#{$size} {
    margin-top: #{$size}px !important;
  }
  .mar-b-#{$size} {
    margin-bottom: #{$size}px !important;
  }
  .mar-tb-#{$size} {
    margin-bottom: #{$size}px !important;
    margin-top: #{$size}px !important;
  }
  .mar-lr-#{$size} {
    margin-left: #{$size}px !important;
    margin-right: #{$size}px !important;
  }
}

$minWidths: 50, 100, 150, 200, 250, 300, 350, 400, 450, 500, 550, 600, 650, 700;
@each $minWidth in $minWidths {
  .min-width-#{$minWidth} {
    min-width: #{$minWidth}px !important;
  }
}

.border-dashed{
  border: 1px dashed #aab7b8;
}
.border-dotted{
  border: 1px dotted #aab7b8;
}

.border-light{
  border: 1px solid #aab7b8;
  &.selected{
    border: 1px solid #00a1c9;
    background: #f1faff;
  }
}

.relative{
  position: relative;
}

.pointer{
  cursor: pointer !important;
}

.vertical-center-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
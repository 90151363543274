.use-suggested-value{
  font-weight: bold;
  color: #1d9fed;
  background: whitesmoke;
  padding: 2px 6px;
  cursor: pointer;
  &:hover {
    color: #076ca9;
  }
}
